/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
// external

// components
import { SnackbarAlert } from 'components/SnackbarAlert';
import { useSanityBaseLanguage } from '@guider-global/sanity-hooks';

// store

export function NoNetwork() {
  const { getBaseLanguage } = useSanityBaseLanguage({});
  const baseLanguage = getBaseLanguage();
  const inBrowser = typeof navigator !== 'undefined';

  const [online, setOnline] = useState(
    inBrowser && typeof navigator.onLine === 'boolean'
      ? navigator.onLine
      : true,
  );

  useEffect(() => {
    const goOnline = () => {
      setOnline(true);
    };

    const goOffline = () => {
      setOnline(false);
    };

    window.addEventListener('online', goOnline);
    window.addEventListener('offline', goOffline);

    return () => {
      window.removeEventListener('online', goOnline);
      window.removeEventListener('offline', goOffline);
    };
  }, []);

  return (
    <>
      <SnackbarAlert
        open={!online}
        severity="warning"
        alertContent={
          baseLanguage.generic_errors?.no_internet_connection ||
          'No Internet Connection'
        }
      />
    </>
  );
}
